import styled from 'styled-components';

export const Container = styled.div`
    padding: 20px;
    color: #000;
`;

export const ContainerPlan = styled.div`
    border: 2px solid var(--color-secondary);
    margin-top: 20px;
    border-radius: 20px;
    min-width: 200px;

    @media (max-width: 456px) {
        min-width: 300px;
    }
`;

export const ContText = styled.div`
    padding: 25px 20px 0 20px;

    #lite-2 {
        font-weight: 400;
    }

    h5 {
        color: var(--text-color-quartenary);
        font-size: 14px;
    }
`;

export const Text = styled.div`
   color: var(--text-color-terciary);

   hr {
       margin-top: 5px;
   }
`;

export const SubText = styled.div`
   padding-top: 20px;
   color: var(--text-color-terciary);
`;

export const Price = styled.div`
   display: flex;
   align-items: center;
   padding-top: 20px;
   color: var(--color-secondary);

   h1 {
       font-size: 65px;
       padding-left: 10px;
   }

   h2 {
       padding-top: 25px;
   }
`;

export const Includes = styled.div`
    padding: 35px 20px 0 20px;

    h3 {
        color: var(--text-color-terciary);
    }
`;

export const TextIncludes = styled.div`
    display: flex;
    padding-top: 25px;
    align-items: center;

    p {
        padding-left: 10px;
        color: var(--text-color-quartenary);
    }
`;

export const Button = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    h5 {
        color: var(--color-white);
    }

    button {
        margin: 10px 0 20px 0;
        width: 200px;
        height: 50px;
        border: none;
        border-radius: 10px;

        background-color: var(--color-secondary);
        color: var(--color-white);
        font-weight: 600;
        transition: 0.5s;

        &:hover {
            background: transparent;
            border: 2px solid var(--color-secondary);
            color: var(--color-secondary);
        }
    }
`;
