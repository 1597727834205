import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 60px;
    display: flex;
    justify-content: space-around;

    .section_text {
        display: flex;
        flex-direction: column;
        justify-content: center;

        padding: 30px 0 0 0;

        h1 {
            font-size: 35px;
            color: var(--text-color-terciary);
        }

        p {
            max-width: 600px;
            font-size: 16px;
            line-height: 30px;
            color: var(--text-color-quartenary);
            padding-top: 20px;
        }

        .section_buttons {
            padding-top: 50px;
            
            button {
                margin: 10px 20px 0 0;
                width: 160px;
                height: 60px;
                border: none;
                border-radius: 10px;
                font-weight: 600;
            }
        
            #btn_watch {
                background-color: var(--color-secondary);
                color: var(--color-white);
                transition: 0.4s;
        
                &:hover {
                    background-color: var(--color-quartenary);
                }
            }
        
            #btn_read {
                background-color: var(--color-white);
                color: var(--color-primary);
                border: 2px solid var(--color-primary);
                transition: 0.4s;
        
                &:hover {
                    color: var(--color-secondary);
                    border: 2px solid var(--color-secondary);
                }
            }
        }
    }

    @media (max-width: 1149px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .section_text {
            .section_buttons {
                padding-top: 50px;
            }
        }

        .react-player {
            margin-top: 70px;
        }
    }

    @media (max-width: 707px) {
        .react-player {
            margin-top: 30px;
            padding-top: 40px;
        }
    }

    @media (max-width: 669px) {
        margin-top: 20px;

        .section_text {
            h1 {
                font-size: 60px;
            }    

            p {
                font-size: 17px;
            }

            .section_buttons {
                padding-top: 30px;
            }
        }
    }

    @media (max-width: 607px) {
        .react-player {
            margin-top: 0;
            padding-top: 80px;
        }
    }

    @media (max-width: 449px) {
        margin-top: 5px;

        .section_text {
            h1 {
                font-size: 50px;
            }    

            p {
                font-size: 15px;
                width: 360px;
            }

            .section_buttons {
                button {
                    margin: 10px 10px 0 0;
                    width: 140px;
                    height: 55px;
                }
            }
        }
    }

    @media (max-width: 495px) {
        .react-player {
            margin-top: 0;
            padding-top: 120px;
            position: relative;
            bottom: 50px;
        }
    }

    @media (max-width: 447px) {
        .react-player {
            margin-top: 0;
            padding-top: 150px;
            position: relative;
            bottom: 80px;
        }
    }

    @media (max-width: 383px) {
        .react-player {
            margin-top: 0;
            padding-top: 180px;
            position: relative;
            bottom: 110px;
        }
    }

    @media (max-width: 320px) {
        .react-player {
            margin-top: 0;
            padding-top: 190px;
            position: relative;
            bottom: 130px;
        }
    }
`;

