import React from 'react';

import { Container, ContSection, All, Circle, ConstText } from './styles';
import { MdDirectionsCar, MdBuild, MdGroupWork } from 'react-icons/md';

function SectionTwo() {
  return (
      <Container>
          <ContSection>
              <All id="first">
                <Circle style={{ background: "#89c03e" }}>
                    <MdBuild size="2em" color="#ffffff"/>
                </Circle>

                <ConstText>
                    <h4>Manutenção</h4>
                    <p>
                        Planeje e acompanhe a manuntenção dos equipamentos
                        de sua equipe com o módulo de manutenção.
                    </p>
                </ConstText>
              </All>

              <All>
                <Circle style={{ background: "#455862" }}>
                    <MdDirectionsCar size="2em" color="#ffffff"/>
                </Circle>

                <ConstText>
                    <h4>Frota</h4>
                    <p>
                        Monitore a utilização dos veiculos da empresa
                        com controle de motoristas, quilometragem, abastecimento 
                        e manutenção periódica.
                    </p>
                </ConstText>
              </All>

              <All>
                <Circle style={{ background: "#89c03e" }}>
                    <MdGroupWork size="2em" color="#ffffff"/>
                </Circle>

                <ConstText className="end">
                    <h4>Reparos e Assistencia Técnica</h4>
                    <p>
                        Gerencie os itens recebidos para reparo com 
                        controle de insumos e apontamento de horas 
                        para execução do serviço.
                    </p>
                </ConstText>
             </All>
          </ContSection>
      </Container>
  );
}

export default SectionTwo;