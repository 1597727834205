import React, { useState, useEffect } from 'react';

import { 
    Container, 
    ContainerPlan, 
    ContText, 
    Text, 
    SubText, 
    Price, 
    Includes, 
    TextIncludes, 
    Button 
} 
from './styles';

import api from '../../../services/api';

import { MdDone } from "react-icons/md";

function ProPlan() {
  const [month, setMonth] = useState([]);

  useEffect(() => {
    async function loadMonth() {
      const response = await api.get('/plans');
      const { data } = response;
      setMonth(data);
    }

    loadMonth();
  }, [])

  const planMonth = month.filter(plan => plan.id === 2);

  return ( 
      <Container>
          {planMonth.map(plan => (
             <ContainerPlan>
                <ContText>
                    <Text>
                        <h1>{plan.title}</h1>
                        <hr color="#95c11f" width="50px"/>
                    </Text>

                    <SubText>
                        <h4>{plan.description}.</h4>
                    </SubText>

                    <Price>
                        <h2>R$</h2>
                        <h1>{plan.price}0</h1>
                    </Price>
                    <h5>Por usuário.</h5>
                </ContText>

                <Includes>
                    <h3>Este Plano Inclui:</h3>
                    <TextIncludes>
                        <MdDone color="#95c11f" size="1.25em"/>
                        <p>{plan.benefits[0]}</p>
                    </TextIncludes>
                </Includes>

                <Button>
                    <a href={`/assinar?planId=${plan.id}`}>
                        <button type="submit">Experimente Agora</button>
                    </a>
                </Button>
             </ContainerPlan> 
          ))}
      </Container> 
  );
}

export default ProPlan;