import React from 'react';

import { Container } from './styles';
import ReactPlayer from 'react-player';
import Equipe_Video from '../../images/Home/Equipe_Remota.mp4';

function Home() {
  return (
      <Container id="home">
          <div className="section_text">
            <h1>Equipe Remota (Field Service)</h1>
            <p>
              Plataforma completa para pequenas e médias
              empresas fazerem o gerenciamento de seus
              funcionários e vendedores externos.
            </p>
            <div className="section_buttons">
              <a href="/contato">
                <button id="btn_watch" type="submit">Contato</button>
              </a>
              <a href="#read_service">
                <button id="btn_read" type="submit">Ler Mais</button>
              </a>
            </div>
          </div>
          <div className="player-wrapper">
            <ReactPlayer
              controls
              width='auto'
              className='react-player'
              url={Equipe_Video}
            />
          </div>
      </Container>
  );
}

export default Home;