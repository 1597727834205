import styled from 'styled-components';

export const Container = styled.div`
    background: #FAF2FF;
    border-radius: 20px;
    margin-top: 100px;
    margin: 90px 10px 0 10px;

    @media (max-width: 414px) {
        margin: 0;
    }
`;


export const Title = styled.div`
    text-align: center;
    padding: 40px 0 20px 0;
    color: var(--text-color-terciary);
`;

export const ContPlans = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 20px;
`;