import React from 'react';

import { Container, ImageCont, TextCont, Title, SectionRowOne, SectionCont, Icon } from './styles';
import Imagem2 from '../../../images/Home/img_2.svg';
import { MdDevices, MdSentimentVerySatisfied, MdTimeline, MdAttachMoney } from "react-icons/md";

function ContentTwo() {
  return (
    <Container>
        <TextCont>
            <Title>
            <h1>Valores Equipe Remota</h1>
            <p>
                Nossos valores nos fazem ser a plataforma ideal
                para seu negócio e te acompanhar na jornada pela
                melhoria contínua dos produtos e processos.
            </p>
            </Title>

            <SectionRowOne>
                <SectionCont>
                    <Icon><MdDevices size="2em" color="#ffffff"/></Icon>
                    <div className="text">
                        <h3>Melhoria todo dia</h3>
                        <p>Sempre atentos a necessidade dos <br/> nossos usuários.</p>
                    </div>
                </SectionCont>

                <SectionCont>
                    <Icon><MdSentimentVerySatisfied size="2em" color="#ffffff"/></Icon>
                    <div className="text">
                        <h3>Respeito ao Usuário</h3>
                        <p>Atender o usuário é nossa total<br/>prioridade.</p>
                    </div>
                </SectionCont>
            </SectionRowOne>

            <SectionRowOne>
                <SectionCont>
                    <Icon><MdTimeline size="2em" color="#ffffff"/></Icon>
                    <div className="text">
                        <h3>Geração de Valor</h3>
                        <p>Ferramentas que façam sentido<br/>para o seu negócio.</p>
                    </div>
                </SectionCont>

                <SectionCont>
                    <Icon><MdAttachMoney size="2em" color="#ffffff"/></Icon>
                    <div className="text">
                        <h3>Transparência</h3>
                        <p>Preços justos e transparentes.</p>
                    </div>
                </SectionCont>
            </SectionRowOne>
        </TextCont>

        <ImageCont>
            <img src={Imagem2} alt="Imagem2" width="500"/>
        </ImageCont>
    </Container>
  );
}

export default ContentTwo;