import React from 'react';

import { Container, Wrapper, Portal, Help } from './styles';

import PortalImg from '../../images/Products/platform.svg'; 
import HelpImg from '../../images/Products/portal.svg'; 

function Products() {
  return(
      <Container id="produtos">
          <Wrapper>
            <Portal>
                <img src={PortalImg} alt="Portal" width="450px" />
                <h2>Portal Equipe Remota</h2>
                <a href="https://portal.equiperemota.com.br/">
                    <button className="portal">Acessar</button>
                </a>
            </Portal>

            <Help>
                <img src={HelpImg} alt="Ajuda" width="450px" />
                <h2>Central de Ajuda - Equipe Remota</h2>
                <a href="https://ajuda.equiperemota.com.br/">
                    <button className="help">Acessar</button>
                </a>
            </Help>
          </Wrapper>
      </Container>
  );
}

export default Products;