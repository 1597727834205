import React, { useState, useEffect } from 'react';

import { 
  Container, 
  ContainerPlan, 
  ContText, 
  Text, 
  SubText, 
  Price, 
  TextIncludes, 
  Includes, 
  Button 
} 
from './styles';

import api from '../../../services/api';

import { MdDone } from "react-icons/md";

function FreePlan() {
  const [yearly, setYearly] = useState([]);

  useEffect(() => {
    async function loadYearly() {
      const response = await api.get('/plans');
      const { data } = response;
      setYearly(data);
    }

    loadYearly();
  }, [])

  const planYearly = yearly.filter(plan => plan.id === 1);
  console.log(planYearly);

  return (
    <Container>
     {planYearly.map(plan => (
       <ContainerPlan>
          <ContText>
            <Text>
              <h1>{plan.title}</h1>
              <hr color="#fff" width="50px" />
            </Text>

            <SubText>
              <h4>{plan.description}.</h4>
            </SubText>

            <Price>
              <h2>R$</h2>
              <h1>{plan.price}0</h1>
            </Price>
            <h5>Por usuário/Mês.</h5>
        </ContText>

        <Includes>
          <h3>Este Plano Inclui: </h3>
          <TextIncludes>
            <MdDone color="#fff" size="1.25em" />
            <p>{plan.benefits[0]}</p>
          </TextIncludes>
        </Includes>

        <Button>
          <a href={`/assinar?planId=${plan.id}`}>
            <button type="submit">Experimente Agora</button>
          </a>
        </Button>
       </ContainerPlan>
     ))}
    </Container>
  );
}

export default FreePlan;