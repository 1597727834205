import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Header from '../components/Header';
import Home from '../components/Home/index';
import Services from '../components/Services/index';
import Biplan from '../components/Biplan/index';
import Content from '../components/Content/index';
import Plans from '../components/Plans/index';
import Products from '../components/Products/index';
import Footer from '../components/Footer/index';
import GlobalStyle from '../global/styles';

const IndexPage = () => (
  <Layout>
    <GlobalStyle />
    <Header />
    <Home />
    <Services />
    <Biplan />
    <Content />
    <Plans />
    <Products />
    <Footer /> 
    <SEO title="Home" /> 
  </Layout>
)

export default IndexPage
