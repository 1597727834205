import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 90px 80px 50px 80px; 

    @media (max-width: 1247px) {
        flex-direction: column;
    }

    @media (max-width: 562px) {
        margin: 50px; 
    }

    @media (max-width: 499px) {
        margin: 20px 30px 50px 30px; 
    }

    @media (max-width: 360px) {
        margin: 0 30px 50px 30px; 
    }
`;

export const ImageCont = styled.div`
    background: var(--color-secondary);
    height: 430px;
    flex: 0.5;

    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    img {
        padding-top: 95px;
    }

    @media (max-width: 1247px) {
        img {
            width: 500px;
            margin: 0 auto;
            padding: 0;
            position: relative;
            top: 80px;
            display: flex;
        }
    }

    @media (max-width: 675px) {
        img {
            top: 70px;
            width: 400px;
        }
    }

    @media (max-width: 562px) {
        img {
            top: 60px;
            width: 350px;
        }
    }

    @media (max-width: 499px) {
        img {
            top: 50px;
            width: 300px;
        }
    }

    @media (max-width: 360px) {
        img {
            top: 45px;
            width: 270px;
        }
    }

    @media (max-width: 320px) {
        img {
            top: 40px;
            width: 250px;
        }
    }
`;

export const Text = styled.div`
    padding-left: 70px;
    flex: 1;

    h1 {
        font-size: 50px;
        color: var(--text-color-terciary);
    }
    
    p {
        font-size: 18px;
        margin-top: 20px;
        color: var(--text-color-quartenary);
    }

    @media (max-width: 1247px) {
        padding: 0;
        display: flex;
        flex-direction: column;
        padding-top: 50px;

        p {
            font-size: 22px;
        }
    }

    @media (max-width: 675px) {
        h1 {
            font-size: 40px;
        }

        p {
            font-size: 18px;
        }
    }

    @media (max-width: 499px) {
        h1 {
            font-size: 35px;
        }
    }

    @media (max-width: 360px) {
        h1 {
            font-size: 28px;
        }

        p {
            font-size: 16px;
        }
    }
`;

export const Check = styled.div`
    display: flex;
    align-items: baseline;

    p {
        padding-left: 10px;
        font-size: 18px;
        color: var(--text-color-quartenary);
    }

    margin: 35px 20px 0 0;

    @media (max-width: 1247px) {
        p {
            font-size: 22px;
        }
    }

    @media (max-width: 675px) {
        p {
            font-size: 20px;
        }
    }

    @media (max-width: 499px) {
        p {
            font-size: 18px;
        }
    }

    @media (max-width: 360px) {
        p {
            font-size: 14px;
        }

        margin: 20px 20px 0 0;
    }

    @media (max-width: 320px) {
        p {
            font-size: 13px;
        }
    }
`;

export const CircleCheck = styled.div`
        width: 35px;
        height: 35px;
        border-radius: 100%;
        background: var(--color-quartenary);

        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 1247px) {
           width: 40px;
           height: 40px;
        }

        @media (max-width: 675px) {
            width: 35px;
            height: 35px;
        }

        @media (max-width: 360px) {
            width: 28px;
            height: 28px;

            svg {
                width: 1.3em;
            }
        }
`;