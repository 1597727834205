import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 100px 80px 50px 80px; 

    @media (max-width: 1247px) {
        flex-direction: column-reverse;
    }

    @media (max-width: 562px) {
        margin: 50px; 
    }

    @media (max-width: 499px) {
        margin: 20px 30px 50px 30px; 
    }

    @media (max-width: 360px) {
        margin: 0 30px 50px 30px; 
    }
`;

export const ImageCont = styled.div`
    background: var(--color-secondary);
    height: 430px;

    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    img {
        position: relative;
        top: 95px;
    }
    
    @media (max-width: 1247px) {
        img {
            width: 500px;
            margin: 0 auto;
            display: flex;
        }
    }

    @media (max-width: 633px) {
        height: 310px;
        img {
            width: 450px;
            position: relative;
            top: 20px;
            padding-right: 30px;
        }
    }

    @media (max-width: 585px) {
        height: 310px;
        img {
            padding-right: 40px;
            top: 40px;
            width: 450px;
        }
    }

    @media (max-width: 499px) {
        height: 250px;
        img {
            width: 350px;
            padding-right: 30px;
        }
    }

    @media (max-width: 414px) {
        img {
            width: 350px;
        }
    }

    @media (max-width: 375px) {
        img {
            width: 330px;
            top: 50px;
        }
    }

    @media (max-width: 365px) {
        img {
            width: 310px;
            top: 60px;
        }
    }

    @media (max-width: 320px) {
        height: 230px;
        img {
            width: 280px;
        }
    }
`;

export const TextCont = styled.div`
    width: 100%;
    position: static;

    @media (max-width: 1247px) {
        padding-top: 50px;
    }
`;

export const Title = styled.div`
    h1 {
        font-size: 50px;
        text-align: left;
        color: var(--text-color-terciary);
    }

    p {
        font-size: 18px;
        margin-top: 20px;
        color: var(--text-color-quartenary);
        max-width: 600px;
    }

    @media (max-width: 659px) {
        p {
            padding-bottom: 30px;
        }
    }

    @media (max-width: 633px) {
        h1 {
            font-size: 40px;
        }
    }

    @media (max-width: 414px) {
        h1 {
            font-size: 35px;
        }

        p {
            font-size: 17px;
        }
    }

    @media (max-width: 360px) {
        h1 {
            font-size: 28px;
        }

        p {
            font-size: 16px;
        }
    }
`;

export const SectionRowOne = styled.div`
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-top: 30px;

    @media (max-width: 1247px) {
        width: 100%;
    }

    @media (max-width: 659px) {
        flex-wrap: wrap;
        margin: 0;
    }
`;

export const SectionCont = styled.div`
    h3 {
        color: var(--text-color-terciary);
        margin-top: 5px;
    }

    p {
        margin-top: 10px;
        color: var(--text-color-quartenary);
    }

    @media (max-width: 659px) {
        display: flex;
        align-items: center;
        padding-top: 10px;
        
        .text {
            padding-left: 20px;
        }

        h3 {
            margin-top: 15px;
        }
    }
`;

export const Icon = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background: var(--color-quartenary);

    display: flex;
    justify-content: center;
    align-items: center;
`;