import React from 'react';

import { Container, Content, Title } from './styles';

import SectionOne from './SectionOne/index';
import SectionTwo from './SectionTwo/index';
import SectionThree from './SectionThree/index';

function Services() {
  return(
      <Container id="servicos">
          <Title>
              <h1>
                Por que o Equipe Remota é a melhor plataforma de
                Equipe Externa (Field Service) para sua empresa?
              </h1>
              <p>
                Descubra as funcionalidades que
                fazem do Equipe Remota uma plataforma
                diferenciada e completa que faz muito mais que
                apenas controlar sua equipe de campo.
              </p>
          </Title>
      
          <Content>
                <SectionOne />
                <SectionTwo />
                <SectionThree />
          </Content>
      </Container>
  );
}

export default Services;