import styled from 'styled-components';

export const Container = styled.div`

    h1 {
        text-align: center;
        color: var(--text-color-terciary);
    }

    @media (max-width: 561px) {
        h1 {
            font-size: 25px;
        }
    }
`;

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 30px;
    text-align: center;
    color: var(--text-color-terciary);

    button {
        width: 250px;
        height: 55px;
        margin-top: 40px;
        border: none;
        border-radius: 10px;
        font-weight: bold;
        color: #fff;
        transition: 0.5s;
    }

    @media (max-width: 1274px) {
        flex-wrap: wrap;
    }
`;


export const Portal = styled.div`
    .portal {
        background-color: var(--color-secondary);

        &:hover {
            background-color: var(--color-primary);
        }
    }

    @media (max-width: 561px) {
        img {
            width: 350px;
        }

        button {
            width: 250px;
            height: 50px;
        }
    }

    @media (max-width: 320px) {
        img {
            width: 250px;
        }
    }
`;

export const Help = styled.div`
    .help {
        background-color: var(--color-primary);

        &:hover {
            background-color: var(--color-secondary);
        }
    }

    @media (max-width: 1274px) {
        margin-top: 50px;
    }

    @media (max-width: 561px) {
        img {
            width: 350px;
        }

        button {
            width: 250px;
            height: 50px;
        }
    }

    @media (max-width: 320px) {
        img {
            width: 250px;
        }
    }
`;
