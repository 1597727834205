import React from 'react';

import ContentTwo from './ContentTwo/index';

import { Container, ImageCont, Text, Check, CircleCheck } from './styles';
import Imagem1 from '../../images/Home/img_1.svg';
import { MdDone } from "react-icons/md";

function Content() {
  return(
    <>
      <Container id="segmentos">
          <ImageCont>
            <img src={Imagem1} alt="Imagem1" width="500"/>
          </ImageCont>

          <Text>
             <h1>O que temos de a mais!</h1>
             <p>
              Funcionalidades que são nativas da plataforma e
              auxiliarão na gestão da empresa e das
              respectivas equipe de campo de forma integrada.
             </p>

             <Check>
                <CircleCheck>
                  <MdDone color="#ffffff" size="1.5em" />
                </CircleCheck>
                <p>Gestão de tarefas</p>
             </Check>

             <Check>
                <CircleCheck>
                  <MdDone color="#ffffff" size="1.5em" />
                </CircleCheck>
                <p>Execução de projetos de clientes</p>
              </Check>

              <Check>
                <CircleCheck>
                  <MdDone color="#ffffff" size="1.5em" />
                </CircleCheck>
                <p>Controle de veículos e equipamentos</p>
              </Check>

              <Check>
                <CircleCheck>
                  <MdDone color="#ffffff" size="1.5em" />
                </CircleCheck>
                <p>Comunicação entre Colaboradores</p>
              </Check>
          </Text>
      </Container>

      <ContentTwo/> 
    </>
  );
}

export default Content;