import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
`;

export const ContainerPlan = styled.div`
    background: #F7F7F7;
    margin-top: 20px;
    border-radius: 20px;
    min-width: 400px;
    background-color: var(--color-secondary);
    color: var(--color-white);

    @media (max-width: 456px) {
        min-width: 300px;
    }
`;

export const ContText = styled.div`
    padding: 25px 20px 0 20px;

    #lite-2 {
        font-weight: 400;
    }
`;

export const Text = styled.div`
   hr {
       margin-top: 5px;
   }
`;

export const SubText = styled.div`
   padding-top: 20px;
`;

export const Price = styled.div`
   display: flex;
   align-items: center;
   padding-top: 20px;

   h1 {
       font-size: 65px;
       padding-left: 10px;
   }

   h2 {
       padding-top: 25px;
   }
`;

export const Includes = styled.div`
    padding: 30px 20px 0 20px;
`;

export const TextIncludes = styled.div`
    display: flex;
    align-items: center;
    padding-top: 25px;

    p {
        padding-left: 10px;
    }
`;

export const Button = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;

    h5 {
        color: var(--text-color-terciary);
    }

    button {
        margin: 10px 0 20px 0;
        width: 200px;
        height: 50px;
        border: none;
        border-radius: 10px;

        background-color: var(--color-white);
        color: var(--color-secondary);
        font-weight: 600;
        transition: 0.5s;

        &:hover {
            background: transparent;
            border: 1px solid var(--color-white);
            color: var(--color-white);
        }
    }
`;