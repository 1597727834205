import styled from 'styled-components';

export const Container = styled.div`

`;

export const ContSection = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 1024px) {
        flex-wrap: wrap;
        justify-content: center;
    }
`;

export const All = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;

    @media (max-width: 1024px) {
        padding: 25px 30px 25px 30px;
    }
`;

export const Circle = styled.div`
    min-width: 70px;
    min-height: 70px;
    border-radius: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 414px) {
        min-width: 60px;
        min-height: 60px;
    }
`;

export const ConstText = styled.div`
    max-width: 350px;
    padding-left: 20px;

    h4 {
        color: var(--text-color-terciary);
    }

    p {
        color: var(--text-color-quartenary);
        padding-top: 5px;
    }

    @media (max-width: 1024px) {
        h4 {
            font-size: 22px;
        }
    
        p {
            font-size: 16px;
        }
    }

    @media (max-width: 414px) {
        h4 {
            font-size: 18px;
        }
    
        p {
            font-size: 14px;
        }
    }

    @media (max-width: 336px) {
        h4 {
            font-size: 16px;
        }
    
        p {
            font-size: 12px;
        }
    }
`;