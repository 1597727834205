import React from 'react';

import { Container, Title, ContPlans } from './styles';
import FreePlan from './FreePlan/index';
import ProPlan from './ProPlan/index';
import Enterprise from './Enterprise/index';

function Plans() {
  return(
      <Container id="planos">
          <Title>
              <h1>Conheça Nossos Planos!</h1>
          </Title>

          <ContPlans>
            <FreePlan />
            <ProPlan /> 
            <Enterprise />
          </ContPlans>
      </Container>
  );
}

export default Plans;