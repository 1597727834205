import React from 'react';

import { Container, SectionTextBiplan, Text, Buttons } from './styles';
import BiplanImage from '../../images/Home/biplan.svg';

function Biplan() {
  return(
      <Container>
          <SectionTextBiplan>
            <Text>
                <h1>
                  O Equipe Remota organiza e otimiza toda sua
                  operação, além de gerenciar com excelência
                  suas equipes de campo.
                </h1>
                <p>
                  Empresas que possuem equipe de campo
                  precisam ter uma operação muito “afinada” e
                  toda integrada, garantindo desta forma que seus
                  custos operacionais sejam minimizados e por
                  consequência suas receitas aumentadas.
                  Pensando nisso a plataforma Equipe Remota foi
                  desenvolvida incluindo as principais necessidade
                  da operação de uma pequena/média empresa,
                  permitindo que ocorra uma gestão eficiente e
                  integrada mesmo sem a utilização de outros
                  sistemas na empresa.
                </p>
            </Text>
            <Buttons>
                <a href="/assinar">
                  <button id="btn_sign" type="submit">Assinar</button>
                </a>
                
                <a href="/contato">
                  <button id="btn_contact" type="submit">Entre em Contato!</button>
                </a>
            </Buttons>
          </SectionTextBiplan>
          <img src={BiplanImage} alt="biplan" width={500}/>
      </Container>
  );
}

export default Biplan;