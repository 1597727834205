import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 90px;

  @media (max-width: 495px) {
    margin-top: 20px;
  }

  @media (max-width: 477px) {
    margin-top: 0;
  }

  @media (max-width: 499px) {
    position: relative;
    bottom: 30px;  
    
    p {
      font-size: 15px;
      max-width: 500px;
    }
  }

  @media (max-width: 379px) {
    position: relative;
    bottom: 60px;  
  }

`;

export const Title = styled.div`
    text-align: center;
    color: var(--text-color-terciary);

    h1 {
      max-width: 800px;
      margin: 0 auto;
    }

    p {
        padding-top: 20px;
        max-width: 700px;
        margin: 0 auto;
        font-size: 20px;
        color: var(--text-color-quartenary);
    }

    @media (max-width: 662px) {
        padding: 10px;
        p {
            font-size: 16px;
        }

        h1 {
            font-size: 25px;
        }
    }
`;

export const Content = styled.div`
    background: #FAF2FF;
    display: flex;
    flex-wrap: wrap;
    margin: 60px 20px 0 20px;

    border-top-left-radius: 40px;
    border-top-right-radius: 40px;

    @media (max-width: 662px) {
        margin: 40px 10px 0 10px;
    }
`;
