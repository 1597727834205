import React from 'react';

import { 
    Container, 
    ContainerPlan, 
    ContText, 
    Text, 
    SubText, 
    Includes, 
    TextIncludes, 
    Button 
} 
from './styles';

import { MdDone } from "react-icons/md";

function Enterprise() {
  return (
      <Container>
        <ContainerPlan>
            <ContText>
                <Text>
                    <h1>Enterprise</h1>
                    <hr color="#455862" width="50px"/>
                </Text>

                <SubText>
                    <h4>Para empresas que precisam de uma atenção <br/> e acompanhamento especial.</h4>
                </SubText>
            </ContText>

            <Includes>
                <h3>Este Plano Inclui:</h3>
                <TextIncludes>
                    <MdDone color="#455862" size="1.25em"/>
                    <p>Implementação Personalizada.</p>
                </TextIncludes>

                <TextIncludes>
                    <MdDone color="#455862" size="1.25em" />
                    <p>Suporte via fone.</p>
                </TextIncludes>

                <TextIncludes>
                    <MdDone color="#455862" size="1.25em" />
                    <p>Acompanhamento estratégico de um especialista.</p>
                </TextIncludes>

                <TextIncludes>
                    <MdDone color="#455862" size="1.25em" />
                    <p>Usuários ilimitados.</p>
                </TextIncludes>
            </Includes>

            <Button>
                <a href="/contato">
                  <button type="submit">Fale com o Comercial</button>
                </a>
            </Button>
        </ContainerPlan>
      </Container>
  );
}

export default Enterprise;