import React from 'react';

import { Container, ContSection, All, Circle, ConstText } from './styles';
import { MdClearAll, MdSettings, MdReceipt } from 'react-icons/md';

function SectionOne() {
  return (
      <Container id="read_service">
          <ContSection>
              <All>
                <Circle style={{ background: "#455862" }}>
                    <MdClearAll size="2em" color="#ffffff"/>
                </Circle>

                <ConstText>
                    <h4>Gestão de serviço de campo</h4>
                    <p>
                        Controle todas as estapas de execução dos 
                        serviços em campo com rastreabilidade total.
                    </p>
                </ConstText>
              </All>

              <All>
                <Circle style={{ background: "#89c03e" }}>
                    <MdSettings size="2em" color="#ffffff"/>
                </Circle>

                <ConstText>
                    <h4>CRM</h4>
                    <p>
                        Gerencie seus orçamentos e propostas 
                        comerciais em uma unica plataforma.
                    </p>
                </ConstText>
              </All>

              <All>
                <Circle style={{ background: "#455862" }}>
                    <MdReceipt size="2em" color="#ffffff"/>
                </Circle>

                <ConstText>
                    <h4>Projetos</h4>
                    <p>
                        Gerencie a execução de multiplos serviços
                        com o módulo de gestão de projetos.
                    </p>
                </ConstText>
             </All>
          </ContSection>
      </Container>
  );
}

export default SectionOne;