import React from 'react';

import { Container, ContSection, All, Circle, ConstText } from './styles';
import { MdAddShoppingCart, MdShoppingCart, MdPeople } from 'react-icons/md';

function SectionTwo() {
  return (
      <Container>
          <ContSection>
              <All id="first">
                <Circle style={{ background: "#455862" }}>
                    <MdShoppingCart size="2em" color="#ffffff"/>
                </Circle>

                <ConstText>
                    <h4>Compras e Estoque</h4>
                    <p>
                        Planeje a compra e estoque de materiais consumíveis
                        e equipamentos de terceiros.
                    </p>
                </ConstText>
              </All>

              <All>
                <Circle style={{ background: "#89c03e" }}>
                    <MdAddShoppingCart size="2em" color="#ffffff"/>
                </Circle>

                <ConstText>
                    <h4>Despesas</h4>
                    <p>
                        Conrole total de despesas da equipe com emissão
                        de vouchers e autorizações para despesas 
                        internas e externas.
                    </p>
                </ConstText>
              </All>

              <All>
                <Circle style={{ background: "#455862" }}>
                    <MdPeople size="2em" color="#ffffff"/>
                </Circle>

                <ConstText className="end">
                    <h4>Recursos Humanos</h4>
                    <p>
                        Mantenha o diretório de funcionários e terceiros atualizado 
                        com informações de cursos e treinamentos, eventos programados 
                        e documentação pessoal.
                    </p>
                </ConstText>
             </All>
          </ContSection>
      </Container>
  );
}

export default SectionTwo;