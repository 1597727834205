import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 20px 0 20px;
  background: var(--color-primary);
  color: var(--color-white);
  border-radius: 20px;

  display: flex;
  justify-content: space-around;

  position: relative;
  bottom: 8px;

  @media (max-width: 1274px) {
      img {
          width: 450px;
          margin-right: 50px;
      }
  }

  @media (max-width: 1003px) {
    flex-direction: column-reverse;

    img {
        width: 430px;
        padding: 80px 20px 0 0;
        margin: 0 auto;
    }
  }

  @media (max-width: 499px) {
    bottom: 40px;
  }  

  @media (max-width: 479px) {
    img {
        width: 380px;
    }
  }  
  
  @media (max-width: 414px) {
    img {
        width: 300px;
    }
  }

  @media (max-width: 379px) {
    bottom: 70px;
  }
  
  @media (max-width: 360px) {
    img {
        width: 260px;
    }
  }

  @media (max-width: 662px) {
    margin: 0 10px 0 10px;
  }
`;

export const SectionTextBiplan = styled.div``;

export const Text = styled.div`
    padding: 70px 40px 40px 40px;
    max-width: 900px;

    h1 {
        font-size: 40px;
    }

    p {
        margin-top: 40px;
        font-size: 16px;
        color: #D3CECE;
        text-align: justify;
        line-height: 35px;
    }

    @media (max-width: 1152px) {
        h1 {
            font-size: 50px;
        }
    }

    @media (max-width: 551px) {
        h1 {
            font-size: 44px;
        }
    }

    @media (max-width: 479px) {
        p {
            font-size: 18px;
        }
    }

    @media (max-width: 414px) {
        h1 {
            font-size: 34px;
        }

        p {
            font-size: 16px;
        }
    }

    @media (max-width: 360px) {
        h1 {
            font-size: 28px;
        }

        p {
            font-size: 14px;
        }
    }
`;

export const Buttons = styled.div`
    padding: 0 40px 70px 40px;
    width: 60%;
    
    button {
        margin: 10px 20px 0 0;
        width: 140px;
        height: 60px;
        border: none;
        border-radius: 10px;
        font-weight: 600;
        transition: 0.6s;
    }

    #btn_sign {
        background-color: var(--color-button-login);
        color: var(--color-white);

        &:hover {
            background-color: var(--color-quartenary);
            color: var(--color-white);
        }
    }

    #btn_contact {
        background: transparent;
        border: 1px solid var(--color-white);
        color: var(--color-white);

        &:hover {
            border: 1px solid var(--color-quartenary);
            color: var(--color-white);
        }
    }

    @media (max-width: 1274px) {
        width: 80%;
    }

    @media (max-width: 1152px) {
        width: 100%;
    }

    @media (max-width: 479px) {
       button {
           width: 140px;
       }
    }

    @media (max-width: 360px) {
        button {
            width: 110px;
        }

        #btn_contact {
            position: relative;
            bottom: 5px;
        }
     }

     @media (max-width: 360px) {
        button {
            width: 90px;
        }
     }
`;
